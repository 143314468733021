import React, { useState, useEffect } from "react"
import {
  Chip,
  Stack,
  Card,
  Typography,
  TextField,
  Switch,
  Button,
  Box,
} from "@mui/material"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import Moment from "moment"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider, DateRangePicker } from "@material-ui/lab";
import counsellorDefault from "../images/default/counsellorDefault.jpeg"
import StarRateIcon from "@mui/icons-material/StarRate"
import { fetchCounsellors, fetchCounsellorTimeTrack, fetchCounsellorStatusApi, fetchCounsellorVerifyApi } from "../state"

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const Content = styled.div`
  height: 100%;
`

const ImageContent = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
`

const Image = styled.img`
  border-radius: 5px;
  height: 220px;
`

const Heading = styled.div`
  font-family: "Roboto";
  color: #a5a4ab;
  font-size: 14px;
`

const HeadingInfo = styled.div`
  font-family: "Roboto";
  color: #2f3338,
  font-size: 14px;
`

const CounsellorInfo = styled.div`
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
`

const ListenerName = styled.h1`
  padding-bottom: 8px;
  padding-right: 8px;
`

const CounsellorIDType = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  padding-bottom: 8px;
`

const CounsellorRow = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  margin-top: 10%;
`

const RowInfo = styled.div`
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`

export const SingleCounsellor = ({ patientData = {} }) => {
  const [selectedDate, handleDateChange] = React.useState([null, null]);
  const dispatch = useDispatch()
  const loginData = useSelector((state) => state.login.user)
  const counsellorTimeTrack = useSelector((state) => state?.counsellors?.timeTracking);
  const counsellorArray = useSelector((state) => state?.counsellors?.counsellor);
  const user = useSelector((state) => state.login.user)
  patientData === null && typeof history !== "undefined" && history.go(-1)
  const [state, setState] = useState({ patientData })
  const counsellorStatus = useSelector((state) => state.patients.status)
  const counsellorVerify = useSelector((state) => state.patients.verify)
  const [isStatus, setIsStatus] = useState(patientData.status === 1 ? true : false)
  const [isVerified, setIsVerified] = useState(patientData.verified)
  const [listeners, setListeners] = useState([]);
  const [startendDate, setStartEndDate] = React.useState([Moment().format("YYYY-MM-DD hh:mm:ss"), Moment().format("YYYY-MM-DD hh:mm:ss")])

  console.log(Moment(startendDate[0]).format("YYYY-MM-DD hh:mm:ss"), 'startendDatestartendDatestartendDate')
  console.log(Moment(startendDate[1]).format("YYYY-MM-DD hh:mm:ss"), 'end end')

  const durationActiveTime = Moment.duration(counsellorTimeTrack?.totalActiveTime, 'minutes');
  const durationTimeSpent = Moment.duration(counsellorTimeTrack?.totalTimeSpent, 'minutes');

  useEffect(() => {
    dispatch(fetchCounsellors(loginData))
    dispatch(fetchCounsellorTimeTrack(patientData.id, Moment(startendDate[0]).format("YYYY-MM-DD hh:mm:ss"), Moment(startendDate[1]).format("YYYY-MM-DD hh:mm:ss")));
  }, []);

  console.log(patientData, 'patientData.status')
  console.log(counsellorArray, 'counsellorArray')

  function toggleStatus(event, listenerID) {
    let isChecked = event.target.checked;
    setIsStatus(isChecked);
    dispatch(fetchCounsellorStatusApi(listenerID, isChecked))
    dispatch(fetchCounsellors(loginData))
  }

  function toggleVerified(event, listenerID) {
    let isChecked = event.target.checked;
    setIsVerified(isChecked);
    dispatch(fetchCounsellorVerifyApi(isChecked, listenerID))
    dispatch(fetchCounsellors(loginData))
  }

  const descriptionText = patientData.hasOwnProperty("descriptionText") ? patientData.descriptionText.split(',') : [];
  // console.log(patientData.hasOwnProperty("descriptionText") && patientData.descriptionText.split(','), "000")

  const onclickFetch = () => {
    console.log(counsellorTimeTrack, 'counsellorTimeTrack')
    dispatch(fetchCounsellorTimeTrack(patientData.id, Moment(startendDate[0]).format("YYYY-MM-DD hh:mm:ss"), Moment(startendDate[1]).format("YYYY-MM-DD hh:mm:ss")));
  }

  return (
    <>
      <Wrapper>
        <Content>
          <Card sx={{ height: 400, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "20px" }}>
            <ImageContent>
              <Image
                src={
                  patientData.imageUrl
                    ? patientData.imageUrl
                    : counsellorDefault
                }
              />
            </ImageContent>
            <CounsellorInfo>
              <Stack flexDirection="row">
                <ListenerName>
                  {patientData.listenerName ? patientData.listenerName : "NA"}
                </ListenerName>
                <StarRateIcon sx={{width: 20, height: 20, color: "green"}} />
                <Typography sx={{ fontSize: "16px", color: "green" }}>
                  {patientData.rating ? patientData.rating : "NA"}
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ paddingBottom: "8px", flexFlow: "wrap" }}>
                {descriptionText.map((text, i) => (
                  <Chip key={i} label={text} color="primary" sx={{mb: 1, ml: 1}} />
                ))}
              </Stack>
              <CounsellorIDType>
                <Heading>Counsellor ID:</Heading>
                <HeadingInfo>&nbsp;{patientData.id}</HeadingInfo>
              </CounsellorIDType>
              <CounsellorIDType>
                <Heading>Counsellor Type:</Heading>
                <HeadingInfo>
                  &nbsp;
                  {patientData.listenerType ? patientData.listenerType : "NA"}
                </HeadingInfo>
              </CounsellorIDType>
              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Heading>Status:</Heading>
                <Switch
                  checked={isStatus}
                  onChange={(event) => toggleStatus(event, patientData?.id)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </CounsellorInfo>
          </Card>
          <Card sx={{ width: "100%", mt: 2, padding: "5%"}}>
          <ListenerName>Working Hours</ListenerName>
          <Stack sx={{ mt: 5, flexDirection: "row" }}>
            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateRangePicker
                startText="Start Date"
                endText="End Date"
                value={startendDate}
                onChange={(newValue) => {
                  setStartEndDate(newValue)
                }}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField {...endProps} />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider> */}
           <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText="Start Date"
              endText="End Date"
              value={startendDate}
              onChange={(newValue) => {
                setStartEndDate(newValue)
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
            <button
              style={{
                marginLeft: "15px",
                width: "90px",
                padding: "10px",
                height: "55px",
                backgroundColor: "#1876d1",
                color: "#fff",
                borderRadius: "5px",
                borderColor: "#1876d1"
              }} onClick={onclickFetch}>Fetch</button>
          </Stack>
          <Card sx={{ mt: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "5%" }}>
            <Stack>
            <Heading>Total Time Spent</Heading>
                <HeadingInfo>
                {counsellorTimeTrack?.totalActiveTime === null ? "NA" : `${durationTimeSpent.days()} days ${durationTimeSpent.hours()} hours ${durationTimeSpent.minutes()} mins`}
                </HeadingInfo>
            </Stack>
            <Stack sx={{pl: 2}}>
            <Heading>Total Active Time</Heading>
                <HeadingInfo>
                {counsellorTimeTrack?.totalTimeSpent === null ? "NA" : `${durationActiveTime.days()} days ${durationActiveTime.hours()} hours ${durationActiveTime.minutes()} mins`}
                </HeadingInfo>
              </Stack>
            </Card>
          </Card>
        </Content>
        <RowInfo>
          <Card sx={{ height: 400, display: "flex", flexDirection: "row", padding: "20px"}}>
            <CounsellorInfo>
              <ListenerName>Profile</ListenerName>
              <CounsellorIDType>
                <Heading>Score:</Heading>
                <HeadingInfo>
                  &nbsp;{patientData.score ? patientData.score : "NA"}
                </HeadingInfo>
              </CounsellorIDType>
              <CounsellorIDType>
                <Heading>Total Conversations:</Heading>
                <HeadingInfo>
                  &nbsp;
                  {patientData.totalConversations
                    ? patientData.totalConversations
                    : "NA"}
                </HeadingInfo>
              </CounsellorIDType>
              <CounsellorIDType>
                <Heading>Training Status:</Heading>
                <HeadingInfo>
                  &nbsp;
                  {patientData.trainingStatus
                    ? patientData.trainingStatus
                    : "NA"}
                </HeadingInfo>
              </CounsellorIDType>
              <CounsellorIDType>
                <Heading>Organization Desc:</Heading>
                <HeadingInfo>
                  &nbsp;
                  {patientData.organizationDesc
                    ? patientData.organizationDesc
                    : "NA"}
                </HeadingInfo>
              </CounsellorIDType>
              <CounsellorIDType>
                <Heading>Category Desc:</Heading>
                <HeadingInfo>
                  &nbsp;
                  {patientData.categoryDesc ? patientData.categoryDesc : "NA"}
                </HeadingInfo>
              </CounsellorIDType>
              <CounsellorIDType>
                <Heading>Education Desc:</Heading>
                <HeadingInfo>
                  &nbsp;
                  {patientData.educationDesc ? patientData.educationDesc : "NA"}
                </HeadingInfo>
              </CounsellorIDType>
              <CounsellorIDType>
                <Heading>Created:</Heading>
                <HeadingInfo>
                  &nbsp;
                  {patientData.created &&
                    Moment(patientData.created, "DD-MM-YYYY").format(
                      "DD MMM YYYY"
                    )}
                </HeadingInfo>
              </CounsellorIDType>
              <CounsellorIDType>
                <Heading>Last status updated:</Heading>
                <HeadingInfo>
                  &nbsp;
                  {patientData.lastStatusUpdated
                    ? Moment(
                        patientData.lastStatusUpdated,
                        "DD-MM-YYYY"
                      ).format("DD MMM YYYY")
                    : "NA"}
                </HeadingInfo>
              </CounsellorIDType>
              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Heading>Verified:</Heading>
                <Switch
                  checked={isVerified}
                  onChange={(event) => toggleVerified(event, patientData?.id)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </CounsellorInfo>
          </Card>
        </RowInfo>
      </Wrapper>
    </>
  )
}

