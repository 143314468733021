import { navigate } from "gatsby"
import React, { useState } from "react"
import { Layout } from "../../components/Layout"
import { SingleCounsellor } from "../../components/SingleCounsellor"
import { PatientsSection } from "../../style/patients"

function Counsellor({ location = {} }) {
  const [showRangePicker, setShowRangePicker] = useState(false)
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ])
  if (location.state === null || undefined) {
    navigate("/counsellors")
  }

  return (
    <div>
      <Layout>
        <PatientsSection>
          <SingleCounsellor
            patientData={location.state && location.state.data}
          />
        </PatientsSection>
      </Layout>
    </div>
  )
}

export default Counsellor
